<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Addressbook</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-address-modal
                >Add Address</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table
              responsive
              small
              striped
              hover
              :items="addresses"
              :fields="fields"
            >
              <template #cell(actions)="row">
                <b-button
                  @click="ShowEditModal(row.item.id)"
                  size="sm"
                  variant="primary"
                >
                  <i class="flaticon2-edit icon-sm"></i
                ></b-button>
                <b-button
                  @click="deleteaddress(row.item.id)"
                  class="ml-2"
                  variant="danger"
                  size="sm"
                >
                  <i class="flaticon2-trash icon-sm"></i
                ></b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal
      id="add-address-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addaddress"
      size="xl"
      title="Add Address"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Name">
              <b-form-input v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Phone">
              <b-form-input v-model="form.phone"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Email">
              <b-form-input v-model="form.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-8">
            <b-form-group label="Street Address">
              <b-form-input v-model="form.street_address"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="City">
              <b-form-input v-model="form.city"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Country">
              <b-form-select
                :options="countries"
                value-field="name"
                text-field="name"
                v-model="form.country"
                @change="changeCountry"
                size="md"
                class=""
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="State">
              <b-form-select
                :options="states"
                value-field="name"
                text-field="name"
                v-model="form.state"
                size="md"
                class=""
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select State</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Zip code">
              <b-form-input v-model="form.zip"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="update-address-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="updateaddress"
      title="Update Address"
      size="xl"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="Name">
              <b-form-input v-model="form.name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Phone">
              <b-form-input v-model="form.phone"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Email">
              <b-form-input v-model="form.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-8">
            <b-form-group label="Street Address">
              <b-form-input v-model="form.street_address"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="City">
              <b-form-input v-model="form.city"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Country">
              <b-form-select
                :options="countries"
                value-field="name"
                text-field="name"
                v-model="form.country"
                @change="changeCountry"
                size="md"
                class=""
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="State">
              <b-form-select
                :options="states"
                value-field="name"
                text-field="name"
                v-model="form.state"
                size="md"
                class=""
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select State</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Zip code">
              <b-form-input v-model="form.zip"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- </form> -->
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<style>
  .form-group {
    margin-bottom: 0.7rem !important
  }
</style>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      addresses: [],
      errors: [],
      states: [],
      allstates: [],
      countries: [],
      isLoading: false,
      fields: [
        "name",
        "phone",
        "email",
        "street_address",
        "city",
        "country",
        "state",
        "zip",
        "actions",
      ],
      form: {
        name: null,
        phone: null,
        email: null,
        street_address: null,
        city: null,
        country: null,
        country_id: null,
        state: null,
        zip: null,
        id: null,
      },
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getAddressbook();
    this.getAllCountries();
    this.getAllStates();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Addressbook" }]);
  },
  methods: {
    checkErrors() {
      if (
        this.form.name &&
        this.form.phone &&
        this.form.street_address &&
        this.form.city &&
        this.form.country &&
        this.form.state &&
        this.form.zip
      ) {
        return true;
      }

      this.errors = [];

      if (!this.form.zip) {
        this.errors.push("Zip code is required.");
      }
      if (!this.form.country) {
        this.errors.push("Country is required.");
      }
      if (!this.form.state) {
        this.errors.push("State is required.");
      }
      if (!this.form.city) {
        this.errors.push("City is required.");
      }
      if (!this.form.street_address) {
        this.errors.push("Street Address is required.");
      }
      if (!this.form.phone) {
        this.errors.push("Phone is required.");
      }
      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    resetModal() {
      this.form.name = null;
      this.form.phone = null;
      this.form.email = null;
      this.form.street_address = null;
      this.form.city = null;
      this.form.country = null;
      this.form.country_id = null;
      this.form.state = null;
      this.form.zip = null;
    },
    addaddress(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("addressbook", this.form)
          .then(() => {
            this.getAddressbook();
            this.$nextTick(() => {
              this.$bvModal.hide("add-address-modal");
              this.makeToastVariant(
                "success",
                "Address added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    updateaddress(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("addressbook", this.form.id, this.form)
          .then(() => {
            this.getAddressbook();
            this.$nextTick(() => {
              this.$bvModal.hide("update-address-modal");
              this.makeToastVariant(
                "info",
                "Address updated successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    getAddressbook() {
      this.isLoading = true;
      ApiService.get("addressbook")
        .then(({ data }) => {
          
          this.addresses = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("addressbook", id)
        .then(({ data }) => {
          this.form = data;
          this.changeCountryForEdit();
          this.isLoading = false;
          this.$bvModal.show("update-address-modal");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deleteaddress(id) {
      this.isLoading = true;
      ApiService.delete("addressbook", id)
        .then(() => {
          this.getAddressbook();
          this.makeToastVariant(
            "warning",
            "Address deleted successfully",
            "Success"
          );
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllCountries() {
      ApiService.get("getcountries")
        .then(({ data }) => {
          this.countries = data;
          this.form.country = this.countries.length
            ? this.countries[0].name
            : null;
          
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllStates() {
      ApiService.get("getstates")
        .then(({ data }) => {
          
          this.allstates = data;
          this.states = data;
          if (
            this.countries.filter((x) => x.name == this.form.country).length > 0
          ) {
            if (
              this.allstates.filter(
                (x) =>
                  x.country_id ==
                  this.countries.filter((x) => x.name == this.form.country)[0]
                    .id
              ).length > 0
            ) {
              this.states = this.allstates.filter(
                (x) =>
                  x.country_id ==
                  this.countries.filter((x) => x.name == this.form.country)[0]
                    .id
              );
            }
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    changeCountry() {
      if (
        this.countries.filter((x) => x.name == this.form.country).length > 0
      ) {
        if (
          this.allstates.filter(
            (x) =>
              x.country_id ==
              this.countries.filter((x) => x.name == this.form.country)[0].id
          ).length > 0
        ) {

          this.form.country_id = this.countries.filter((x) => x.name == this.form.country)[0].id; 
          this.states = this.allstates.filter(
            (x) =>
              x.country_id ==
              this.countries.filter((x) => x.name == this.form.country)[0].id
          );
          this.form.state = null;
        }
      }
      //If Country has only one State auto select that state PW-68
      if (this.states.length == 1) {
        this.form.state = this.states[0].name;
      }
    },
    changeCountryForEdit() {
      if (
        this.countries.filter((x) => x.name == this.form.country).length > 0
      ) {
        if (
          this.allstates.filter(
            (x) =>
              x.country_id ==
              this.countries.filter((x) => x.name == this.form.country)[0].id
          ).length > 0
        ) {
          this.form.country_id = this.countries.filter((x) => x.name == this.form.country)[0].id; 
          this.states = this.allstates.filter(
            (x) =>
              x.country_id ==
              this.countries.filter((x) => x.name == this.form.country)[0].id
          );
        }
      }
      //If Country has only one State auto select that state PW-68
      if (this.states.length == 1) {
        this.form.state = this.states[0].name;
      }
    },
  },
};
</script>
